<template>
  <section class="csn-user-docs-form-section">
    <h2 class="csn-user-docs-form-name">{{ t('upload_file') }}</h2>

    <p class="csn-user-docs-form-description">
      {{ t('upload_restrictions') }}
    </p>
    <Message :type="messageType" :message="message" :hasBottom="true" />
    <Loader v-if="IS_DOC_TYPE_PENDING" />
    <Form class="form-inline" :ref="formRef" v-else>
      <div class="row-fluid">
        <Dropdown
          :dataSource="typeList"
          :label="t('type')"
          :name="name.TYPE"
          v-model="formData[name.TYPE]"
          :validators="[validator.required]"
          :serverError="serverError[name.TYPE]"
          :placeholder="t('please_select')"
          containerClass="csn-user-docs-file-type"
          displayValue="id"
        />
        <Dropdown
          :dataSource="dateList"
          :label="t('expiry_date')"
          :name="name.EXPIRY_DAY"
          v-model="formData[name.EXPIRY_DAY]"
          :validators="[validator.required]"
          :serverError="serverError[name.EXPIRY_DAY]"
          containerClass="csn-user-docs-expiry-date"
          :class="{
            'csn-user-docs-date-server-error': !!serverError[name.EXPIRY_DAY],
          }"
          :hidden="!hasExpiryDate"
        />
        <Dropdown
          :dataSource="monthList"
          :name="name.EXPIRY_MONTH"
          v-model="formData[name.EXPIRY_MONTH]"
          :validators="[validator.required]"
          :serverError="serverError[name.EXPIRY_MONTH]"
          containerClass="csn-user-docs-expiry-month"
          :hidden="!hasExpiryDate"
        />
        <Dropdown
          :dataSource="yearList"
          :name="name.EXPIRY_YEAR"
          v-model="formData[name.EXPIRY_YEAR]"
          :validators="[validator.required]"
          :serverError="serverError[name.EXPIRY_YEAR]"
          containerClass="csn-user-docs-expiry-year"
          :hidden="!hasExpiryDate"
        />

        <Dropdown
          :dataSource="dateList"
          :label="t('issue_date')"
          :name="name.ISSUE_DAY"
          v-model="formData[name.ISSUE_DAY]"
          :validators="[validator.required]"
          :serverError="serverError[name.ISSUE_DAY]"
          containerClass="csn-user-docs-expiry-date"
          :class="{
            'csn-user-docs-date-server-error': !!serverError[name.ISSUE_DAY],
          }"
          :hidden="!hasIssueDate"
        />
        <Dropdown
          :dataSource="monthList"
          :name="name.ISSUE_MONTH"
          v-model="formData[name.ISSUE_MONTH]"
          :validators="[validator.required]"
          :serverError="serverError[name.ISSUE_MONTH]"
          containerClass="csn-user-docs-expiry-month"
          :hidden="!hasIssueDate"
        />
        <Dropdown
          :dataSource="yearList"
          :name="name.ISSUE_YEAR"
          v-model="formData[name.ISSUE_YEAR]"
          :validators="[validator.required]"
          :serverError="serverError[name.ISSUE_YEAR]"
          containerClass="csn-user-docs-expiry-year"
          :hidden="!hasIssueDate"
        />
        <FileField
          :label="t('choose_file')"
          :name="name.FILE"
          :validators="[validator.hasFile]"
          v-model="formData[name.FILE]"
          :serverError="serverError[name.FILE]"
          buttonClass="csn-user-docs-bttn"
          containerClass="csn-user-docs-file-field"
        />
        <button
          type="submit"
          class="btn-primary btn csn-user-docs-submit-bttn"
          @click="submitForm"
          :disabled="isSubmitting"
        >
          <ButtonLoader v-if="isSubmitting" />
          <span v-else>{{ t('upload_file') }}</span>
        </button>
      </div>
    </Form>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formMixin } from '@/mixins'
import {
  USER_DOCUMENT_FORM,
  Module,
  DOC_TYPE_DICTIONARY,
  IS_DOC_TYPE_PENDING,
  EMPTY_STRING,
  Digit,
  UPDATE_DOCUMENT_DICTIONARY,
  EMPTY_OBJECT,
} from '@/constants'
import { setCalendarList, isPlainObject } from '@/helpers'
import { AuthApi } from '@/api'

const name = {
  TYPE: 'documents_add[documentType]',
  FILE: 'documents_add[file]',
  EXPIRY_DAY: 'documents_add[expiryDate][day]',
  EXPIRY_MONTH: 'documents_add[expiryDate][month]',
  EXPIRY_YEAR: 'documents_add[expiryDate][year]',
  ISSUE_DAY: 'documents_add[issueDate][day]',
  ISSUE_MONTH: 'documents_add[issueDate][month]',
  ISSUE_YEAR: 'documents_add[issueDate][year]',
}

const formData = {
  [name.TYPE]: EMPTY_STRING,
  [name.FILE]: EMPTY_STRING,
  [name.EXPIRY_DAY]: EMPTY_STRING,
  [name.EXPIRY_MONTH]: EMPTY_STRING,
  [name.EXPIRY_YEAR]: EMPTY_STRING,
  [name.ISSUE_DAY]: EMPTY_STRING,
  [name.ISSUE_MONTH]: EMPTY_STRING,
  [name.ISSUE_YEAR]: EMPTY_STRING,
}

const currentYear = new Date().getFullYear()
const start = currentYear - Digit.FIVE
const end = currentYear + Digit.FIVE

const dateList = setCalendarList({ end: Digit.THIRTY_ONE })
const monthList = setCalendarList({ end: Digit.TWELVE })
const yearList = setCalendarList({ end, start })

export default {
  name: USER_DOCUMENT_FORM,
  mixins: [formMixin],
  components: {
    Form: () => import('@/components/FormData'),
    Dropdown: () => import('@/components/Dropdown'),
    FileField: () => import('@/components/FileField'),
    ButtonLoader: () => import('@/components/ButtonLoader'),
    Message: () => import('@/components/atoms/Message'),
    Loader: () => import('@/components/Loader'),
  },
  data: () => ({
    formData: { ...formData },
  }),
  computed: {
    ...mapState(Module.DOC_TYPE, [DOC_TYPE_DICTIONARY, IS_DOC_TYPE_PENDING]),
    name: () => name,
    dateList: () => dateList,
    monthList: () => monthList,
    yearList: () => yearList,
    t() {
      return this.$createComponentTranslator(USER_DOCUMENT_FORM)
    },
    typeList() {
      return Object.values(this.DOC_TYPE_DICTIONARY)
    },
    selectedTypeId() {
      return this.formData[name.TYPE]
    },
    hasExpiryDate() {
      return this.DOC_TYPE_DICTIONARY[this.selectedTypeId]?.expiryDate
    },
    hasIssueDate() {
      return this.DOC_TYPE_DICTIONARY[this.selectedTypeId]?.issueDate
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(currentFormData) {
        this.message && (this.message = null)
        this.messageType && (this.messageType = null)

        if (!Object.keys(this.serverError).length) {
          return
        }

        Object.keys(this.serverError).forEach((key) => {
          if (currentFormData[key] === this.formDataSubmitCopy[key]) {
            return
          }

          const newServerError = { ...this.serverError }
          delete newServerError[key]

          this.serverError = newServerError
        })
      },
    },
  },
  methods: {
    ...mapActions(Module.DOCUMENT, [UPDATE_DOCUMENT_DICTIONARY]),
    async submitForm() {
      const { isValid } = this.$refs[this.formRef].getValidation()

      if (!isValid) {
        return
      }
      this.isSubmitting = true

      try {
        const request = {
          documentType: this.formData[name.TYPE],
          ...(this.hasExpiryDate
            ? {
                expiryDate: {
                  day: this.formData[name.EXPIRY_DAY],
                  month: this.formData[name.EXPIRY_MONTH],
                  year: this.formData[name.EXPIRY_YEAR],
                },
              }
            : EMPTY_OBJECT),
          ...(this.hasIssueDate
            ? {
                issueDate: {
                  day: this.formData[name.ISSUE_DAY],
                  month: this.formData[name.ISSUE_MONTH],
                  year: this.formData[name.ISSUE_YEAR],
                },
              }
            : EMPTY_OBJECT),
          filename: this.formData[name.FILE].fileName,
          file: this.formData[name.FILE].file,
        }

        const response = await AuthApi.postDocument(request)

        if (!this.validateResponse(response)) {
          return
        }

        const { state, msg, data } = response
        const { errors } = data || {}

        if (state === this.responseState.ERROR) {
          this.formDataSubmitCopy = { ...this.formData }
          this.messageType = this.messageTypeEnum.FAILURE
          this.message = msg

          if (isPlainObject(errors)) {
            this.serverError = {
              ...(errors.filename ? { [name.FILE]: errors.filename?.msg } : {}),
              ...(errors.file ? { [name.FILE]: errors.file?.msg } : {}),
              ...(errors.documentType
                ? { [name.TYPE]: errors.documentType?.msg }
                : {}),

              //NOTE: the following errors object structure may be wrong. I will improve it if I get more details
              ...(errors.expiryDate?.day
                ? { [name.EXPIRY_DAY]: errors.expiryDate.day?.msg }
                : {}),
              ...(errors.expiryDate?.month
                ? { [name.EXPIRY_YEAR]: errors.expiryDate.month?.msg }
                : {}),
              ...(errors.expiryDate?.year
                ? { [name.EXPIRY_DAY]: errors.expiryDate.year?.msg }
                : {}),
              ...(errors.issueDate?.day
                ? { [name.ISSUE_DAY]: errors.issueDate.day?.msg }
                : {}),
              ...(errors.issueDate?.month
                ? { [name.ISSUE_MONTH]: errors.issueDate.month?.msg }
                : {}),
              ...(errors.issueDate?.year
                ? { [name.ISSUE_YEAR]: errors.issueDate.year?.msg }
                : {}),
            }
          }

          return
        }

        this.serverError = {}
        this.formDataSubmitCopy = {}
        this.formData = { ...formData }
        await this.$refs[this.formRef].makeReady({ isReady: false })

        this.messageType = this.messageTypeEnum.SUCCESS
        this.message = msg
        this.UPDATE_DOCUMENT_DICTIONARY()
      } catch (error) {
        console.log(error)
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>
